/* .App {
  text-align: center;
} */
/* :root {
  --black: #000;
  --white: #f5f5f5;
}

body{
  background: var(--background-color);
  color: var(--text-color);
}

body.light{
  --background-color: var(--white);
  --text-color:var(--black);
}
body.dark{
  --background-color: var(--black);
  --text-color:var(--white);
} */

@font-face {
  font-family: 'BreeSerif-Regular';
  src: url('./styles/fonts/BreeSerif-Regular.ttf') format('truetype');
  font-display: swap;
 }

@font-face {
  font-family: 'HelveticaNeue-Bold';
  src: url('./styles/fonts/HelveticaNeue-Bold.otf') format('opentype');
  font-display: swap;
 }

 @font-face {
  font-family: 'HelveticaNeue-Medium';
  src: url('./styles/fonts/HelveticaNeue-Medium.otf') format('opentype');
  font-display: swap;
 }

 @font-face {
  font-family: 'HelveticaNeue';
  src: url('./styles/fonts/HelveticaNeue.otf') format('opentype');
  font-display: swap;
 }

 @font-face {
  font-family: 'HelveticaNeue-Light';
  src: url('./styles/fonts/HelveticaNeue-Light.otf') format('opentype');
  font-display: swap;
 }

 /* @font-face {
  font-family: 'HelveticaNeue-Regular';
  src: url('./styles/fonts/HelveticaNeue-Regular.ttf') format('truetype');
  font-display: swap;
 } */

 body{
  overflow-x: hidden;
 }

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cookie-banner {
  position: fixed;
  bottom: 10px;
  left: 10%;
  right: 10%;
  width: 80%;
  color: #807f8d;
  padding: 0px 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #050420;
  border-radius: 5px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  font-family: 'SFUIDisplayRegular';
}

.cookie-banner p {
  padding-top: 12px;
}

.close {
  height: 30px;
  width: 130px;
  font-size: 12px;
  background-color: #32c167;
  border: none;
  color: #fff;
  border-radius: 2px;
  cursor: pointer;
}

.react-confirm-alert-overlay{
  background-color: #0e1726ba !important;
}

.react-confirm-alert-body>h1{
  color: #0e1726e0 !important; 
  font-family: 'SFUIDisplay-Medium' !important;
  text-align: center !important;
  font-size: 24px !important;
}

.react-confirm-alert-body {
  background: #fff !important;
  color: #0e1726e0 !important; 
  font-family: 'SFUIDisplayRegular' !important;
  text-align: center !important;
  font-size: 13px !important;
}

.react-confirm-alert-button-group{
  justify-content: center !important;
}
.react-confirm-alert-button-group>button{
  background: #0e1726e0 !important;
  color: #fff !important;
  font-family: 'SFUIDisplay-Medium' !important;
}


.react-date-picker__wrapper{
  border: none !important;
}

.react-date-picker__inputGroup{
  min-width: none !important;
  padding: 0px !important;
}
.react-date-picker__button{
  padding: 0px !important;
}

.react-date-picker__clear-button {
  display: none !important;
}



.ag-theme-alpine .ag-root-wrapper{
  border: none !important;
  background: transparent !important;
}

.ag-theme-alpine .ag-header{
  border-bottom: none !important;
  margin-bottom: 5px;
  border-radius: 10px !important;
  min-height: 45px !important;
  border: none !important;
}


.rdrCalendarWrapper{
  position: absolute;
  z-index: 1;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}

.ag-theme-alpine .ag-paging-panel{
  border-top: none !important;
  color: #60677e !important;
  font-family: 'SFUIDisplayRegular' !important;
}



/*date range*/

.rdrStartEdge, .rdrInRange ,.rdrEndEdge{
 color: #7a68c2 !important;
}

.rdrDateDisplayItemActive{
  border-color: #7a68c2 !important;
}





.navbar-dark .navbar-toggler-icon {
  filter: invert(1) !important;
  border-radius: 3px;
}


.dropdown-toggle::after {
  display: none !important;
}