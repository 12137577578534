body, html{
    background-color: #ffffff !important;
    margin:0;
    height:100%;
    overflow:hidden;
}

.construction_img{
    background: url("../../assets/under_construction.jpg");
    background-size: cover;
    width: 100%;
    height: 100vh;
    background-position: top left;
    position: relative;
    
}
.construction_img h2{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: fit-content;
    text-align: center;
    font-family: 'HelveticaNeue-Bold';
    font-size: 70px;
    color: #fff;
    font-weight: bold;
}

.construction_img p{
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: fit-content;
    text-align: center;
    font-family: 'HelveticaNeue';
    padding: 20px 0px;
    font-size: 16px;
    color: #ffffffb8;
    font-weight: 500;
}
/*BANNER 1 SCSS*/

.banner1section {
    // height: 60vh;

    .bannerimg {
        overflow: hidden;
        width: 100%;
    }

    .bannercircle {
        right: 0;
        top: -80%;
        position: absolute;
        width: 100%;
        height: auto;
        margin-right: -58%;
    }

    .banner1top {
        position: relative;
        width: 550px;
        height: 600px;
        z-index: 2;
    }

    .banner1shade{
        position: relative; 
        width: 400px;
        height: 400px;
        z-index: -1;
        margin-top: -32%;
        margin-left: 8%;
    }
}

.textcontent {
    .top_header{
        margin-top: 23%;
    }

    h2 {
        color: #394151;
        font-size: 38px;
        font-family: 'BreeSerif-Regular';
        max-width: 400px;
    }

    p {
        color: #606770;
        font-size: 16px;
        font-family: 'HelveticaNeue';
        max-width: 360px;
    }

    #getbutton {
        background-color: #27AAE1;
        border: none;
        color: #fff;
        border-radius: 10px;
        padding: 15px 50px 15px 50px;
        margin-top: 1%;
        font-size: 14px;
        font-family: 'HelveticaNeue-Medium';
    }

    #getbutton:hover {
        background-color: #1881ae;
        color: white;
    }
}




/*BANNER 2 SCSS*/

.banner2section {
    padding: 50px 0 80px 0;


    .banner2 {
        width: 500px;
        margin-top: -12%;
    }

    .textcontent {
    
        p {
            max-width: 400px;
        }
    }
}

/*BANNER 3 SCSS*/

.banner3section {
    padding: 80px 0 0px 0;

    .banner3 {
        width: 650px;
        margin-top: -25%;
        margin-left: -30%;
    }

    .textcontent {
    
        h2 {
            max-width: 500px;
        }
        p{
            max-width: 370px;
        }
    }

}

.learn_more {
    text-decoration: none;
    font-family: 'HelveticaNeue-Bold';
    color: #1E1C24;
    font-size: 14px;

    .learn_more:hover{
        color: #27AAE1 !important;
    }

    .rightarrow {
        margin-left: 10px;
        color: #27AAE1;
        font-size: 16px;
    }
}



/*BANNER 4 SCSS*/

.banner4section {

    .banner4 {
        position: absolute;
        width: 600px;
        height: 800px;
        margin-top: -10%;
        left: -25%
    }

    .banner4top {
        position: relative;
        width: 600px;
        z-index: 2;
        left: -5%;
        margin-top: -4%;
    }

    .textcontent {
        margin-top: 30%;

        
        p{
            max-width: 400px;
        }
    }

    .textcontent span {
        color: black;
        font-weight: bold;
    }
}

/*BANNER 5 SCSS*/

.banner5section {
    height: auto;


    .textcontent {
    
        h2 {
            max-width: 500px;
        }
    
        p {
            max-width: 340px;
        }
    }
}



/* vk */
.banner5 {
    width: 727px;
    height: 705px;
    bottom: -16%;
    right: -14%;
    position: absolute;
    z-index: 1;
}

.banner5top {
    position: relative;
    width: 100%;
    height: auto;
    z-index: 2;
}

.banner5section {
    height: auto;
    width: 100%;
    display: inline-block;
    position: relative;
    padding: 0px 18px;
}

.LandingFooter_footer_section {

    width: 100%;
    display: inline-block;

}



// @media only screen and (max-width: 1400px) {
//     .banner4 {
//         width: 850px !important;
//         height: 100% !important;
//         margin-top: -25%;
//         margin-left: -100% !important;
//     }

//     .banner4top {
//         position: absolute;
//         width: 600px !important;
//         height: 700px !important;
//         margin-left: -27% !important;
//     }
// }



@media only screen and (max-width: 1200px) {
    // .bannercircle {
    //     display: none;
    // }

    .banner1top {
        width: 400px !important;
        height: 450px !important;
    }
    .banner1shade{
        display: none;
    }

    .banner2 {
        width: 100% !important;
    }

    .banner3section {

        .banner3 {
            width: 650px !important;
            height: 450px !important;
            margin-top: -16% !important;
        }
    
    }

    .banner4 {
        display: none;
    }


    .banner4top {
        width: 500px !important;
        height: 500px !important;
        margin-left: -10% !important;
        margin-top: 0% !important;
    }

    .banner5top {
        width: 500px !important;
        height: 450px !important;
    }

    .banner5 {
        display: none;
    }

}

@media only screen and (max-width: 992px) {

    .construction_img h2{
        font-size: 50px;
    }

    .bannercircle {
        display: none;
    }

    .banner1section {
        margin-top: 80px;
        
        .textcontent{
            margin-top: 30%;
        }
    }

    .banner1top {
        width: 400px !important;
        height: 400px !important;
        margin-top: 30px !important;
        margin-left: -50px !important;
    }

    // .banner2 {
    //     width: 300px !important;
    //     height: 300px !important; 
    // }

    .banner3section {
        padding: 50px 0px;

        .banner3 {
            width: 350px !important;
            height: 350px !important;
            margin-left: 0% !important;
        }
    }

    .banner4section{
        .banner4top {
            width: 400px !important;
            height: 400px !important;
            margin-top: -25% !important;
        }
        .textcontent {
            margin-top: 0%;
        }
    }

    .banner5top {
        width: 350px !important;
        height: 350px !important;
    }

    .textcontent {
        .top_header{
            margin-top: 5% !important;
        }
    
        h2 {
            font-size: 26px;
        }

    }
    
}

@media only screen and (max-width:768px) {

    .construction_img h2{
        font-size: 40px;
    }

    .banner1section {
        margin-top: 70px;
        padding: 50px 0px 80px 0px !important;
        background-color: #cff0ff !important;


        .textcontent{
            margin-top: 0%;
        }
    }

    .banner1top {
       display: none;
    }

    .banner2section {
        padding: 60px 0px 350px 0px;
            .banner2 {
                width: 350px !important;
                height: 300px !important;
                position: absolute !important;
                margin-top: 300px !important;
            }
    }

    .banner3section {
        padding: 50px 0px 0px 0px !important;

        .banner3 {
            width: 300px !important;
            height: 300px !important;
            margin-top: 0% !important;
        }
    }

    .banner4section{
        padding: 0px 0px 350px 0px;

        .banner4top {
            width: 350px !important;
            height: 350px !important;
            margin-left: 12% !important;
            margin-top: 160px !important;
            position: absolute !important;
        }
    }

    .banner5top {
        width: 350px !important;
        height: 350px !important;
    }

}

@media only screen and (max-width:576px) {
    .banner5section {
        padding: 0px !important;
    }

    .construction_img h2{
        font-size: 30px;
    }
   
}

@media only screen and (max-width:500px) {

    .banner1top {
        margin-top: 50px !important;
        margin-left: -5px !important;
    }

    .banner2section {
            .banner2 {
                width: 300px !important;
                height: 250px !important;
                position: absolute !important;
                margin-top: 280px !important;
                margin-left: 12% !important;
            }
    }

    .banner3section {
        padding: 0px !important;

        .banner3 {
            width: 300px !important;
            height: 300px !important;
            margin-top: 0% !important;
        }
    }

    .textcontent {
        .top_header{
            margin-top: 40px !important;
        }
 
        h2 {
            font-size: 24px !important;
            line-height: 26px !important;
        }

        p {
            font-size: 12px !important;
        }
    }

    #getbutton {
        font-size: 12px !important;
    }

    .learn_more {
        font-size: 12px !important;
    }

    .banner4section {
        
        .textcontent {
            margin-top: 0% !important;
        }

        
    .banner4top {
        width: 300px !important;
        height: 300px !important;
    }

    
    }
}

@media only screen and (max-width:380px) {
    .banner1section {
        padding: 0px 0px 60px 0px !important;
    }
    .banner1top {
      display: none;
    }
    .banner2section {
        padding: 35px 0px 350px 0px;
        .banner2 {
            width: 300px !important;
            height: 250px !important;
            position: absolute !important;
            margin-top: 265px !important;
        }
    }

    .banner4section {
        
        .banner4top {
            margin-left: 0% !important;
        }

    }
    .banner5top {
        width: 300px !important;
        height: 300px !important;
        margin-top: 0px !important;
    }

    .textcontent {
        .top_header{
            margin-top: 60px !important;
        }
       
    }

    .construction_img h2{
        font-size: 24px;
    }

}

@media only screen and (max-width:360px) {

    .banner2section {
        .banner2 {
            margin-left: 0% !important;
        }
    }
    .banner5top {
        margin-top: 0px !important;
    }
    
  

}